import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following table lists the metrics available in all of Contact Center and Analytics Studio aggregation and metrics panels, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Data Source`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AI Classified Contacts - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AI Classified Contacts - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of contacts classified by an Artificial Intelligence bot.`}<br />{`Number of contacts * 100 / Total number of contacts`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Click2Call) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Click2Call) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Inbound) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Inbound) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Outbound) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Outbound) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Attributed conversion value - M - Maximum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Attributed conversion value - m - Minimum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Attributed conversion value - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Attributed conversion value - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot Name - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot Name - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bounces - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visits`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bounces - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visits`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of visits where the visitor interacted with only one page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time - .95 - 95th percentile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time - M - Maximum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time - m - Minimum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call-center did not answer (Click2Call) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call-center did not answer (Click2Call) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks canceled - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks canceled - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts (Click2Call) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts (Click2Call) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Click2Call - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Click2Call - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Click2Call) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Click2Call) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Outbound) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Outbound) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads with sale - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads with sale - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads without sale - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closed leads without sale - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Inbound) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Inbound) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Outbound) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Outbound) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests - DAvg - Daily average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts - DAvg - Daily average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts with cobrowse - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts with cobrowse - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call) - M - Maximum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call) - m - Minimum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call) - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call) - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound) - M - Maximum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound) - m - Minimum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound) - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound) - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound) - M - Maximum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound) - m - Minimum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound) - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound) - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits - M - Maximum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits - m - Minimum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Detail Type (experience) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Detail Type (segmentation) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call) - .95 - 95th percentile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call) - M - Maximum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call) - m - Minimum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call) - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call) - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat) - .95 - 95th percentile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat) - M - Maximum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat) - m - Minimum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat) - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat) - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound) - .95 - 95th percentile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound) - M - Maximum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound) - m - Minimum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound) - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound) - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound) - .95 - 95th percentile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound) - M - Maximum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound) - m - Minimum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound) - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound) - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (s) - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (s) - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration - .95 - 95th percentile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration - M - Maximum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration - m - Minimum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Leads`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`Leads`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Form - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Form - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Forwardings - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has transcript? - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has transcript? - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Human validation - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Human validation - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identified contacts (Click2Call) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identified contacts (Click2Call) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (baseline) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with A/B variants) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with profiles) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions (with segments) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Influenced conversions - M - Maximum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Influenced conversions - m - Minimum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Influenced conversions - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Influenced conversions - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Instances - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Client (Click2Call) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Client (Click2Call) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Contact Center (Click2Call) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Contact Center (Click2Call) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Leads - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Live Chat - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Live Chat - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contacts`}</li><li>{`IVR`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Open Leads - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Open Leads - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Page requests - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visits`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Page requests - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visits`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of individual page requests that visitors make during a visit.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pending Callbacks - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pending Callbacks - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RGUs - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sales - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration - .95 - 95th percentile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visits`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration - M - Maximum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visits`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration - m - Minimum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visits`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visits`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visits`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Click2Call) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Click2Call) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Inbound) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Inbound) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Call) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Call) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Chat) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Chat) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Inbound) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Inbound) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Outbound) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Outbound) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (VideoCall) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (VideoCall) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success classified (Click2Call) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success classified (Click2Call) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call - .95 - 95th percentile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call - M - Maximum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call - m - Minimum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration - .95 - 95th percentile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration - M - Maximum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration - m - Minimum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total contacts - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total contacts - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Leads`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfer Requests - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfer Requests - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers with Success - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers with Success - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers without Success - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers without Success - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique contents - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique elements - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique sessions (with prints) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors (with prints) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Content`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique visitors - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Visitors`}</li><li>{`Visits`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Call) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Call) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Chat) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Chat) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Inbound) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Inbound) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Outbound) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Outbound) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (VideoCall) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (VideoCall) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Silence (Click2Call) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Silence (Click2Call) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Voicemail (Click2Call) - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Voicemail (Click2Call) - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VideoCall - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VideoCall - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visits - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Visits`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time - .95 - 95th percentile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time - M - Maximum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time - m - Minimum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time - µ - Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time - ∑ - Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`Contacts`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`metric_ivr_trigger_point_final - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`metric_ivr_trigger_point_final - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`metric_ivr_trigger_point_initial - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`metric_ivr_trigger_point_initial - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`metric_ivr_trigger_point_mid_call - # - Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`metric_ivr_trigger_point_mid_call - % - Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p>{`IVR`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      